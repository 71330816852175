import React from "react";
import { Link } from "gatsby";

const FooterMenusWidgets = ({ }) => {
  return (

    <div className="container-fluid footerbanner">
      <div className="container">
        <div class="row justify-content-between">
          <div className="col-md-6 col-lg-5">
            <h3>Try Upfeed today</h3>
            <p>Start your 14-day free trial today and explore the benefits of  a centralized product feed.</p>
          </div>
          <div className="col-md-6 col-lg-6">
            <Link to={"https://apps.shopify.com/facebook-shopping-feed"} target="_blank">
              <button type="button" className={`btn btn-light btn-md trial-btn mt-md-4`}>Start Free Trial</button>
            </Link>
          </div>
        </div>
      </div>
    </div>

  )
}

export default FooterMenusWidgets
