import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import logo from "../assets/images/upfeed-logo.svg"

const Header = ({ pageContext, toggleBackdrop, ...props }) => {
  const { wp } = useStaticQuery(graphql`
    {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)
  return (
    <header className={`px-md-4 mb-3`} id="site-header">

      <nav className={`navbar navbar-expand-md navbar-light d-flex flex-row justify-content-between`}>

        <Link to={"/"} className="navbar-brand mr-auto">
          <img src={logo} alt="Upfeed" width="100" />
        </Link>

        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse flex-grow-0" id="navbarNav">
          <ul className="navbar-nav d-flex align-items-center">
            <li><Link to="/getting-started" className={`nav-item  menu-item`} activeClassName="active">Getting Started</Link></li>
            <li><Link to="/pricing" className={`nav-item menu-item`} activeClassName="active">Pricing</Link></li>
            <li><Link to="/faq" className={`nav-item menu-item`} activeClassName="active">FAQ</Link></li>
            {/* <li><Link to="/blog" className={`nav-item menu-item`} activeClassName="active">Blog</Link></li> */}
            <li><Link to="/contact" className={`nav-item menu-item`} activeClassName="active">Contact</Link></li>
            <li>
              <Link to={"https://apps.shopify.com/facebook-shopping-feed"} target="_blank">
                <button type="button" class="btn btn-primary btn-md trial-btn nav-btn ml-5 mb-1">Free Trial</button>
              </Link>
            </li>
          </ul>
        </div>
      </nav>

    </header>
  )
}

export default Header
