import React from "react"
import { Link } from "gatsby"
import logo from "../assets/images/footer-logo.svg"

const Footer = ({}) => {
  return (
    <footer id="site-footer" role="contentinfo" className="header-footer-group">
      <div className={`container footer-content`}>
        <div className="row">
          <div className={`col-6 col-md`}>
            <img
              src={logo}
              alt="Upfeed"
              width="100"
              style={{ marginBottom: 10 + "px" }}
            />
          </div>
          <div className="col-6 col-md">
            <h5>Explore</h5>
            <ul className="list-unstyled">
              <li>
                <a href="getting-started">Getting started</a>
              </li>
              <li>
                <a href="pricing">Pricing</a>
              </li>
              {/* <li>
                <a href="blog">Blog</a>
              </li> */}
            </ul>
          </div>
          <div className="col-6 col-md">
            <h5>Company</h5>
            <ul className="list-unstyled">
              {/* <li><a href="privacy-policy">About</a></li> */}
              <li>
                <a href="privacy-policy">Privacy Policy</a>
              </li>
              <li>
                <a href="privacy-policy">Terms</a>
              </li>
            </ul>
          </div>
          <div className="ccol-6 col-md">
            <h5>Get In Touch</h5>
            <ul className="list-unstyled">
              <li>
                <Link to="/contact">Contact us</Link>
              </li>
              <li>
                <Link to="/faq">FAQ</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={`subfooter mt-auto py-2`}>
        <div className="container flex-md-row">
          <div className="row">
            <div className="col-md-6">
              <small className="text-muted me-md-auto">
                © {new Date().getFullYear()} Upfeed | Build in Boston - by Zeng
                Apps{" "}
              </small>
            </div>

            <div className={`col-md-6 text-end`}>
              <small className="text-end">
                <a className="to-the-top" href="#site-header">
                  <span className="to-the-top-short">
                    Scroll to Top{" "}
                    <span className="arrow" aria-hidden="true">
                      ↑
                    </span>
                  </span>
                </a>
              </small>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
